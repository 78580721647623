import React from "react";
import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";

function Model() {
  const [modelLink, setModelLink] = useState("");
  const [noIdStatus, setNoIdStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [viewDescription, setViewDescription] = useState(true);

  useEffect(() => {
    getById(window.location.href.split("/").pop());
  }, []);

  const getById = (id) => {
    setLoader(true);
    let checkForValidMongoDbID = new RegExp("^[0-9a-fA-F]{24}$");
    if (checkForValidMongoDbID.test(id)) {
      setNoIdStatus(false);
      axios
        .get(`https://webarapi.devstree.com/api/3d-model/getQRcode/${id}`, {
          // payload
        })
        .then((response) => {
          setModelLink(response.data.data);
          setLoader(false);
        })
        .catch((err) => {
          console.log("api catch : ", err);
          setLoader(true);
        });
    } else {
      setLoader(false);
      setNoIdStatus(true);
    }
  };

  return (
    <>
      {loader ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          {noIdStatus ? (
            <div className="outer_box no_internet_container">
              <div className="msg_box">
                <div className="logo">
                  <img src={require("./favicon.png")} alt="" />
                </div>
                <p>
                  <span style={{ color: "#97A6E9" }}>Oops!</span>
                  Please scan proper qr code
                </p>
                <span>Please again scan qr code</span>
              </div>
            </div>
          ) : (
            <>
              <model-viewer
                className="model-viewer"
                src={"https://webarapi.devstree.com/" + modelLink?.modelData}
                style={{ width: "100%", height: "500px" }}
                alt="A 3D model of an astronaut"
                shadow-intensity="1"
                camera-controls
                auto-rotate
                touch-action="pan-y"
                ar
              ></model-viewer>
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "6px",
                  fontWeight: "500",
                  textAlign: "center",
                  color: "#272B41",
                  transition: "#272B41 0.3s",
                  padding:'0px 10px'
                }}
              >
                {modelLink.title}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "6px",
                  fontWeight: "500",
                  textAlign: "center",
                  color: "#272B41",
                  transition: "#272B41 0.3s",
                  padding:'0px 10px'
                }}
              >
                {modelLink?.description?.length > 76 && viewDescription
                  ? modelLink?.description?.substring(0, 76)
                  : modelLink?.description}
                {modelLink?.description?.length > 76 && (
                  <span
                    style={{
                      color: "gray",
                      fontSize: "16px",
                      cursor: "pointer",
                      marginLeft: "6px",
                    }}
                    onClick={() => {
                      setViewDescription(!viewDescription);
                    }}
                  >
                    {!viewDescription ? "...less" : "more..."}
                  </span>
                )}
              </p>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Model;
