import React from "react";
import { useState } from "react";
import "./App.css";
import { useMemo } from "react";
import Model from "./Model";

function App() {
  const [networkStatus, setNetworkStatus] = useState(true);

  const handleOnline = (event) => {
    setNetworkStatus(event.type === "online");
  };

  const handleOffline = (event) => {
    setNetworkStatus(event.type === "online");
  };

  useMemo(() => {
    setNetworkStatus(window?.navigator?.onLine);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    return {};
  }, [networkStatus]);

  return (
    <>
      {networkStatus ? (
        <Model />
      ) : (
        <div className="outer_box no_internet_container">
          <div className="msg_box">
            <div className="logo">
              <img
                 src={require("./favicon.png")}
                alt=""
              />
            </div>
            <p>
              <span style={{ color: "#97A6E9" }}>Oops!</span>
              Your internet connection seems to be broken
            </p>
            <span>Please connect and click on reload to continue</span>
            <button
              data-tip="true"
              onClick={() => window.location.reload()}
              type="button"
              className="primary_btn"
              style={{ background: "#97A6E9" }}
            >
              <div className="btn_text">Reload</div>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
